.btn {
  &-primary {
    color: $white;
    fill: $white;
  }
  &-bigtile {
    display: block;
    width: 75%;
    margin: auto;
  }
}

.play-button-circle {
  fill: $primary;
}
