.form-tag {
  display: inline-block;
  padding: .1em .2em;
  margin: .2em .1em;
  border-radius: 2px;
}

.tag-musique,
.tag-jingle,
.tag-instrument,
.tag-chant {
  background-color: $primary;
}

.tag-bruitage,
.tag-mecanique,
.tag-electrique,
.tag-humain,
.tag-animal,
.tag-bricolage,
.tag-sourd,
.tag-outil,
.tag-cuisine,
.tag-organique,
.tag-metallique,
.tag-pas,
.tag-manuel,
.tag-plastique,
.tag-pierre,
.tag-verre,
.tag-vegetal,
.tag-tissu,
.tag-papier,
.tag-appareils {
  background-color: $orange;
}

.tag-ambiance,
.tag-interieur,
.tag-maison,
.tag-bureau,
.tag-industriel,
.tag-commerce,
.tag-sous-sol,
.tag-calme,
.tag-agite,
.tag-air,
.tag-exterieur,
.tag-parcjardin,
.tag-nature,
.tag-lieuxpublics,
.tag-transports,
.tag-sport,
.tag-trafic,
.tag-pluie,
.tag-vent,
.tag-ville,
.tag-nuit,
.tag-jour {
  background-color: $blue;
}

.tag-voix,
.tag-cris,
.tag-chuchotement,
.tag-discussion {
  background-color: $pink;
}
